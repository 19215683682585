
  import { ServiceFactory } from '@/services/ServiceFactory'
  import TableContainer from '@/components/TableContainer.vue'

  export default {
    name: 'InteractionAverageWidget',
    components: {TableContainer},
    props: {
      id: Number,
      cycleId: Number,
    },

    data() {
      return {
        stats: [],
        loading: true,
      }
    },

    async beforeMount() {
      this.update()
      this.loading = false
    },

    computed: {
      average() {
        if (this.isInt(this.stats.average))
          return this.stats.average

        return this.stats.average.toFixed(1)
      },
    },

    watch: {
      cycleId() {
        this.update()
      },
    },

    methods: {
      isInt(n) {
        return n % 1 === 0
      },
      update() {
        ServiceFactory.createStatisticsService().interactionAverage(this.id, this.cycleId).then(
          (response) => this.stats = response.data,
        )
      }
    },
  }
