import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

interface ModuleConfiguration {
  optIn: boolean
  cycle: boolean
  tier: boolean
  target: boolean
  rayon: boolean
  activity: boolean
  omnichannel: boolean
}

export type Dutch = 'nl'
export type English = 'en'
export type French = 'fr'
export type Language = Dutch | English | French

interface Role {
  name: string
  permissions: []
}

export interface User {
  id: number
  name: string
  email: string
  language: Language
  roles: Role[]
  has2FA: boolean
}

interface RootState {
  authenticated: boolean
  authenticatedUser: User | null
  accessToken: string
  modules: ModuleConfiguration
  lastImportDate: string
  basicOptIn: boolean
  multiLanguage: boolean
  issuedMaterials: boolean
}

const vuexLocal = new VuexPersistence<RootState>(
  {
    storage: window.localStorage,
  },
)

Vue.use(Vuex)

export default new Vuex.Store(
  {
    state: {
      authenticated: false,
      authenticatedUser: null,
      accessToken: '',
      modules: {
        optIn: false,
        cycle: false,
        tier: false,
        target: false,
        rayon: false,
        activity: false,
        omnichannel: false,
      },
      lastImportDate: '',
      basicOptIn: false,
      multiLanguage: false,
      issuedMaterials: false,
    },

    mutations: {
      setAuthenticatedUser(state, payload) {
        state.authenticatedUser = payload.authenticatedUser
      },

      setModules(state, payload) {
        state.modules = payload.modules
      },

      setLastImportDate(state, payload) {
        state.lastImportDate = payload.lastImportDate;
      },

      setBasicOptIn(state, payload) {
        state.basicOptIn = payload.basicOptIn;
      },

      setMultiLanguage(state, payload) {
        state.multiLanguage = payload.multiLanguage;
      },

      setIssuedMaterials(state, payload) {
        state.issuedMaterials = payload.issuedMaterials;
      },

      setAccessToken(state, payload) {
        state.accessToken = payload.accessToken
      },

      changeLanguage(state, payload) {
        state.authenticatedUser.language = payload.newLanguage
      },
    },

    actions: {},

    modules: {},

    plugins: [
        vuexLocal.plugin,
    ],
  },
)
