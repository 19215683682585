import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '@/store/index'
import {canAssignProperties, canManageClients} from '@/composables/useGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue'),
  },
  {
    path: '/afgegeven-materiaal',
    name: 'IssuedMaterial',
    component: () => import(/* webpackChunkName: "issued-material-overview" */ '../views/IssuedMaterial/Overview.vue'),
  },
  {
    path: '/locaties',
    name: 'LocationOverview',
    component: () => import(/* webpackChunkName: "location-overview" */ '../views/Location/Overview.vue'),
  },
  {
    path: '/locaties/toevoegen',
    name: 'CreateLocation',
    component: () => import(/* webpackChunkName: "location-create" */ '../views/Location/CreateLocation.vue'),
  },
  {
    path: '/locaties/:id',
    name: 'LocationDetail',
    component: () => import(/* webpackChunkName: "location-detail" */ '../views/Location/Detail.vue'),
  },
  {
    path: '/locaties/:id/bewerken',
    name: 'LocationEdit',
    component: () => import(/* webpackChunkName: "client-edit" */ '../views/Location/EditLocation.vue'),
    beforeEnter: (to, from, next) => {
      if (!canManageClients() && !canAssignProperties())
        return next({ name: 'Forbidden' });

      next()
    }
  },
  {
    path: '/klanten',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "clients" */ '../views/client/Clients.vue'),
  },
  {
    path: '/klanten/filters/:filters',
    name: 'ClientsWithFilters',
    component: () => import(/* webpackChunkName: "clients-with-filters" */ '../views/client/Clients.vue'),
  },
  {
    path: '/klanten/toevoegen',
    name: 'ClientCreate',
    component: () => import(/* webpackChunkName: "client-create" */ '../views/client/Create.vue'),
    beforeEnter: (to, from, next) => {
      if (!canManageClients())
        return next({ name: 'Forbidden' });

      next()
    }
  },
  {
    path: '/klanten/:id/bewerken',
    name: 'ClientEdit',
    component: () => import(/* webpackChunkName: "client-edit" */ '../views/client/Edit.vue'),
    beforeEnter: (to, from, next) => {
      if (!canManageClients() && !canAssignProperties())
        return next({ name: 'Forbidden' });

      next()
    }
  },
  {
    path: '/klanten/:id',
    name: 'ClientDetail',
    component: () => import(/* webpackChunkName: "client-detail" */ '../views/client/ClientDetail.vue'),
  },
  {
    path: '/klanten/:id/toestemming',
    name: 'ClientConsent',
    component: () => import(/* webpackChunkName: "client-consent" */ '../views/client/ClientConsent.vue'),
  },
  {
    path: '/klanten/:id/e-mailadres-toevoegen',
    name: 'AddEmail',
    component: () => import(/* webpackChunkName: "client-add-email" */ '../views/client/ClientAddEmail.vue'),
  },
  {
    path: '/klanten/:id/e-mailadres-bewerken',
    name: 'EditEmail',
    component: () => import(/* webpackChunkName: "client-edit-email" */ '../views/client/ClientEditEmail.vue'),
  },
  {
    path: '/klanten/:id/e-mailadres-toevoegen/secundair',
    name: 'AddSecondaryEmail',
    component: () => import(/* webpackChunkName: "client-add-email" */ '../views/client/ClientAddEmail.vue'),
  },
  {
    path: '/klanten/:id/e-mailadres-bewerken/secundair',
    name: 'EditSecondaryEmail',
    component: () => import(/* webpackChunkName: "client-edit-email" */ '../views/client/ClientEditEmail.vue'),
  },
  {
    path: '/klanten/:id/interacties/',
    name: 'InteractionCreate',
    component: () => import(/* webpackChunkName: "create-interaction" */ '../views/interaction/InteractionCreate.vue'),
  },
  {
    path: '/eigenschappen',
    name: 'Properties',
    component: () => import(/* webpackChunkName: "properties" */ '../views/Properties/PropertiesOverview.vue'),
  },
  {
    path: '/eigenschappen/nieuw',
    name: 'NewProperty',
    component: () => import(/* webpackChunkName: "new-property" */ '../views/Properties/NewProperty.vue'),
  },
  {
    path: '/eigenschappen/:id',
    name: 'EditProperty',
    component: () => import(/* webpackChunkName: "edit-property" */ '../views/Properties/EditProperty.vue'),
  },
  {
    path: '/producten',
    name: 'Products',
    component: () => import(/* webpackChunkName: "products" */ '../views/product/Products.vue'),
  },
  {
    path: '/producten/nieuw',
    name: 'CreateProduct',
    component: () => import(/* webpackChunkName: "create-product" */ '../views/product/CreateProduct.vue'),
  },
  {
    path: '/producten/:id/bewerk',
    name: 'EditProduct',
    component: () => import(/* webpackChunkName: "edit-product" */ '../views/product/EditProduct.vue'),
  },
  {
    path: '/interacties',
    name: 'Interactions',
    component: () => import(/* webpackChunkName: "interactions" */ '../views/interaction/Interactions.vue'),
  },
  {
    path: '/interacties/:id',
    name: 'InteractionDetail',
    component: () => import(/* webpackChunkName: "interaction-detail" */ '../views/interaction/InteractionDetail.vue'),
  },
  {
    path: '/interacties/:id/bewerk',
    name: 'UpdateInteraction',
    component: () => import(/* webpackChunkName: "update-interaction" */ '../views/interaction/UpdateInteraction.vue'),
  },
  {
    path: '/dagbesteding',
    name: 'Activities',
    component: () => import(/* webpackChunkName: "activities" */ '../views/activity/Activities.vue'),
  },
  {
    path: '/reps',
    name: 'Reps',
    component: () => import(/* webpackChunkName: "reps" */ '../views/rep/Reps.vue'),
  },
  {
    path: '/reps/:id',
    name: 'RepDetails',
    component: () => import(/* webpackChunkName: "show-rep" */ '../views/rep/RepDetail.vue'),
  },
  {
    path: '/dagbesteding/',
    name: 'CreateActivity',
    component: () => import(/* webpackChunkName: "create-activity" */ '../views/activity/CreateActivity.vue'),
  },
  {
    path: '/dagbesteding/:date/:id',
    name: 'ActivityDetail1',
    component: () => import(/* webpackChunkName: "create-activity-detail" */ '../views/activity/ActivityDetail.vue'),
  },
  {
    path: '/dagbesteding/:date',
    name: 'ActivityDetail2',
    component: () => import(/* webpackChunkName: "activity-detail" */ '../views/activity/ActivityDetail.vue'),
  },
  {
    path: '/dagbesteding/:id/bewerk',
    name: 'EditActivity',
    component: () => import(/* webpackChunkName: "edit-activity" */ '../views/activity/EditActivity.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
  },
  {
    path: '/omnichannel/emails',
    name: 'EmailOverview',
    component: () => import(/* webpackChunkName: "email-overview" */ '../views/Omnichannel/Email/EmailOverview.vue'),
  },
  {
    path: '/omnichannel/emails/nieuw',
    name: 'EmailEditor',
    component: () => import(/* webpackChunkName: "email-editor" */ '../views/Omnichannel/Email/EmailEditor.vue'),
  },
  {
    path: '/omnichannel/emails/:id',
    name: 'EmailDetail',
    component: () => import(/* webpackChunkName: "email-detail" */ '../views/Omnichannel/Email/EmailDetail.vue'),
  },
  {
    path: '/omnichannel/email-templates',
    name: 'EmailTemplateOverview',
    component: () => import(/* webpackChunkName: "email-template-overview" */ '../views/Omnichannel/EmailTemplate/EmailTemplateOverview.vue'),
  },
  {
    path: '/omnichannel/email-templates/:id',
    name: 'EmailTemplateEditor',
    component: () => import(/* webpackChunkName: "email-template-overview" */ '../views/Omnichannel/EmailTemplate/EmailTemplateEditor.vue'),
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: () => import(/* webpackChunkName: "admin-dashboard" */ '../views/admin/Dashboard.vue'),
  },
  {
    path: '/admin/gebruikers',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/admin/Users.vue'),
  },
  {
    path: '/admin/gebruikers/nieuw',
    name: 'CreateUser',
    component: () => import(/* webpackChunkName: "add-user" */ '../views/admin/AddUser.vue'),
  },
  {
    path: '/admin/gebruikers/:id',
    name: 'ShowUser',
    component: () => import(/* webpackChunkName: "show-user" */ '../views/admin/ShowUser.vue'),
  },
  {
    path: '/admin/gebruikers/:id/bewerk',
    name: 'UpdateUser',
    component: () => import(/* webpackChunkName: "update-user" */ '../views/admin/UpdateUser.vue'),
  },
  {
    path: '/admin/specialismen',
    name: 'SpecialismsView',
    component: () => import(/* webpackChunkName: "specialism-overview" */ '../views/admin/SpecialismsView.vue'),
  },
  {
    path: '/admin/specialismen/nieuw',
    name: 'CreateSpecialismView',
    component: () => import(/* webpackChunkName: "specialism-create" */ '../views/admin/CreateSpecialismView.vue'),
  },
  {
    path: '/admin/specialismen/:id/bewerk',
    name: 'EditSpecialismView',
    component: () => import(/* webpackChunkName: "specialism-edit" */ '../views/admin/EditSpecialismView.vue'),
  },
  {
    path: '/admin/locatietypes',
    name: 'LocationTypesView',
    component: () => import(/* webpackChunkName: "location-types-overview" */ '../views/admin/LocationTypesView.vue'),
  },
  {
    path: '/admin/locatietypes/nieuw',
    name: 'CreateLocationTypeView',
    component: () => import(/* webpackChunkName: "location-types-create" */ '../views/admin/CreateLocationTypeView.vue'),
  },
  {
    path: '/admin/locatietypes/:id/bewerk',
    name: 'EditLocationTypeView',
    component: () => import(/* webpackChunkName: "location-types-edit" */ '../views/admin/EditLocationTypeView.vue'),
  },
  {
    path: '/admin/instellingen',
    name: 'edit-settings-view',
    component: () => import(/* webpackChunkName: "edit-settings-view" */ '../views/admin/EditSettingsView.vue'),
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '../views/error/Forbidden.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/error/NotFound.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Auth' && !store.state.authenticatedUser)
    next({ name: 'Auth' })
  else
    next()
})

export default router
